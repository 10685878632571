// src/components/mdxLayouts/layouts/SectionLayout.js

import React from "react";
import { MDXProvider } from "@mdx-js/react";

/*
 * *** Sectionlayout  ***
 * --------------------------
 * for markdownfiles in mdxSections
 */

const componentsIntroTextLayout = {
  p: (props) => <p className="xl6Light" {...props} />,
};

export const IntroTextLayout = ({ children }) => {
  return (
    <section className={``}>
      <MDXProvider components={componentsIntroTextLayout}>
        {children}
      </MDXProvider>
    </section>
  );
};

const componentsIntroTableLayout = {
  table: (props) => <table className="w-full lg:w-auto lg:ml-56" {...props} />,
  tr: (props) => (
    <tr
      className="border-0 border-gray-800 border-dashed last:border-0"
      {...props}
    />
  ),
  td: (props) => (
    <td className="py-4 align-top first:w-16" {...props} />
  ),
  a: (props) => <a className="font-semibold" {...props} />,
};

export const IntroTableLayout = ({ children }) => {
  return (
    <section className={`mt-12`}>
      <MDXProvider components={componentsIntroTableLayout}>
        {children}
      </MDXProvider>
    </section>
  );
};


